export const CON_EDISON_URL = "https://www.coned.com";
export const CON_EDISON_SCHEDULING_URL = "https://conedev.my.site.com/EVScheduling/s/";

export const CON_EDISON_HOSTING_MAP_URL =
    "https://coned.maps.arcgis.com/apps/MapSeries/index.html?appid=edce09020bba4f999c06c462e5458ac7&entry=5";

export const CON_EDISON_ELECTRIC_VEHICLES_URL = "https://www.coned.com/en/our-energy-future/electric-vehicles";
export const CON_EDISON_ELECTRIC_VEHICLES_POWERREADY_URL =
    "https://www.coned.com/en/our-energy-future/electric-vehicles/power-ready-program";

export const CON_EDISON_DEVELOPER_RESOURCES_URL =
    "https://www.coned.com/en/our-energy-future/electric-vehicles/power-ready-program/contractor-resources";
export const CON_EDISON_DOCUMENTS_AND_TOOLS_URL =
    "https://www.coned.com/en/our-energy-future/electric-vehicles/power-ready-program/contractor-resources/program-documents-tools";

export const CON_EDISON_POWERREADY_NEWS_AND_EVENTS_URL =
    "https://www.coned.com/en/our-energy-future/electric-vehicles/power-ready-program/contractor-resources/news-and-events";

export const CON_EDISON_POWERREADY_FAQ_URL =
    "https://www.coned.com/en/our-energy-future/electric-vehicles/power-ready-program/faqs";

export const CON_EDISON_HOSTING_CAPACITY_URL = "https://www.coned.com/en/business-partners/hosting-capacity";
export const CON_EDISON_TRAINING_AND_VIDEOS_URL =
    "https://www.coned.com/en/our-energy-future/electric-vehicles/power-ready-program/contractor-resources/training-and-videos";

export const CON_EDISON_POWERREADY_MICROMOBILITY =
    "https://www.coned.com/en/our-energy-future/electric-vehicles/micromobility-infrastructure-incentive";
