import { SentryUtil } from "app/frontend/utils/sentry";
SentryUtil.init();

import { createInertiaApp } from "@inertiajs/react";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "../styles";

import { CssBaseline, ThemeProvider } from "@mui/material";
import AdminLayout from "app/frontend/components/layouts/AdminLayout";
import Layout from "app/frontend/components/layouts/Layout";
import POWERREADY_THEME from "app/frontend/theme";
import { ToastContainer } from "react-toastify";
import { logDev } from "../utils/logging";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryHandler from "app/frontend/utils/sentry/ErrorBoundaryHandler";
import { onBoundaryError } from "app/frontend/utils/sentry/onBoundaryError";

import ReactGA from "react-ga4";

const pages = import.meta.glob("../pages/**/*.tsx") as Record<string, any>;
logDev("application.tsx - pages -", pages);

document.addEventListener("DOMContentLoaded", () => {
    // @ts-expect-error - Property 'content' does not exist on type 'Element'.
    const googleAnalyticsId = document.querySelector("meta[name=GOOGLE_ANALYTICS_ID]")?.content;
    logDev("application.tsx - googleAnalyticsId from meta tag -", googleAnalyticsId);
    if (googleAnalyticsId) {
        ReactGA.initialize(googleAnalyticsId);
    } else {
        // Sends to sentry in production
        console.error("application.tsx - is not defined. Skip setting up ReactGA.");
    }

    createInertiaApp({
        resolve: async (pageName: string) => {
            logDev("application.tsx - createInertiaApp - page pageName -", pageName);

            return Promise.resolve(pages[`../pages/${pageName}.tsx`]()).then((_page) => {
                let page = _page;
                page = page && "default" in page ? page.default : page;

                if (page) {
                    page.layout =
                        page.layout ||
                        (pageName.startsWith("admin/") && !pageName.includes("SignIn") ? AdminLayout : Layout);

                    logDev("application.tsx - createInertiaApp - return page -", pageName);
                    return page;
                }
            });
        },

        /**
         * React.StrictMode forces components to be rendered twice in development
         * https://stackoverflow.com/a/60619061/6410635
         */
        setup({ el, App, props }) {
            logDev("application.tsx - render App", { el, App, props: props.initialPage.props });

            createRoot(el!).render(
                <StrictMode>
                    <ToastContainer />
                    <ThemeProvider theme={POWERREADY_THEME}>
                        <CssBaseline />
                        <ErrorBoundary onError={onBoundaryError} fallback={<ErrorBoundaryHandler />}>
                            <App {...props} />
                        </ErrorBoundary>
                    </ThemeProvider>
                </StrictMode>,
            );
        },
    }).catch(console.error);
});
